import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const GoToMeeting = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.3 13.72a3.16 3.16 0 0 0-3.462.124a.63.63 0 0 1-.682.035l-3.137-1.81a.08.08 0 0 1 0-.137l3.12-1.8a.63.63 0 0 1 .685.036a3.16 3.16 0 0 0 3.47.139A3.194 3.194 0 0 0 22.442 6.1a3.158 3.158 0 0 0-5.924 1.773a.63.63 0 0 1-.311.606l-3.136 1.811a.08.08 0 0 1-.118-.068V6.6a.63.63 0 0 1 .372-.573a3.158 3.158 0 1 0-2.64 0a.63.63 0 0 1 .373.573v3.622a.08.08 0 0 1-.119.068L7.804 8.48a.63.63 0 0 1-.311-.605a3.157 3.157 0 1 0-1.307 2.294a.63.63 0 0 1 .687-.038l3.12 1.8a.08.08 0 0 1 0 .137L6.854 13.88a.63.63 0 0 1-.683-.035a3.16 3.16 0 0 0-3.461-.124a3.194 3.194 0 0 0-1.143 4.202a3.159 3.159 0 0 0 5.924-1.792a.63.63 0 0 1 .31-.61l3.137-1.81a.08.08 0 0 1 .119.068V17.4a.63.63 0 0 1-.372.573a3.158 3.158 0 1 0 2.64 0a.63.63 0 0 1-.373-.573v-3.621a.08.08 0 0 1 .118-.069l3.137 1.812a.63.63 0 0 1 .31.609a3.159 3.159 0 0 0 5.924 1.792A3.194 3.194 0 0 0 21.3 13.72"
    />
  </svg>
);

GoToMeeting.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(GoToMeeting);
