import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const MoveUp = props => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="arrows-alt-h"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.type === "icon" ? props.theme.palette.icons.darkInactiveIcon : null}
      d="M3 13c0-2.45 1.76-4.47 4.08-4.91l-1.49 1.5L7 11l4-4.01L7 3L5.59 4.41l1.58 1.58v.06A7.007 7.007 0 0 0 1 13c0 3.87 3.13 7 7 7h3v-2H8c-2.76 0-5-2.24-5-5zm10 0v7h9v-7h-9zm7 5h-5v-3h5v3zM13 4h9v7h-9z"
    />
  </svg>
);

MoveUp.propTypes = {
  theme: propTypes.object,
  type: propTypes.string,
};

export default withTheme()(MoveUp);
