import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const MarkEmailRead = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.95 22l-4.25-4.25l1.4-1.4l2.85 2.85l5.65-5.65l1.4 1.4zM12 11l8-5H4zm0 2L4 8v10h5.15l2 2H4q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v4.35l-2 2V8zm0 0"
    />
  </svg>
);

MarkEmailRead.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(MarkEmailRead);
