import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const GppBadOutlined = props => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={"#F44336"} {...props}>
    <path d="M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M18,11.09c0,4-2.55,7.7-6,8.83 c-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25l6,2.25V11.09z M9.91,8.5L8.5,9.91L10.59,12L8.5,14.09l1.41,1.41L12,13.42l2.09,2.08 l1.41-1.41L13.42,12l2.08-2.09L14.09,8.5L12,10.59L9.91,8.5z" />
  </svg>
);

GppBadOutlined.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(GppBadOutlined);
