import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const MissedVideoCall = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zm-2-1.83V16H5V8h10v.67zm-7.89 2.44L11 15l3.77-3.79-.78-.79L11 13.43l-3.11-3.1h2.55V9.22H6v4.44h1.11z" />
  </svg>
);

MissedVideoCall.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(MissedVideoCall);
