import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const AppearIn = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h12q.825 0 1.413.588T18 6v4.5l4-4v11l-4-4V18q0 .825-.587 1.413T16 20z"
    />
  </svg>
);

AppearIn.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(AppearIn);
