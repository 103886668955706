import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const PersonCancel = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path d="M17.4 13L16 11.6l2.075-2.1L16 7.425L17.4 6l2.1 2.1L21.575 6L23 7.425L20.9 9.5l2.1 2.1l-1.425 1.4l-2.075-2.075zM9 12q-1.65 0-2.825-1.175T5 8t1.175-2.825T9 4t2.825 1.175T13 8t-1.175 2.825T9 12m-8 8v-2.8q0-.85.438-1.562T2.6 14.55q1.55-.775 3.15-1.162T9 13t3.25.388t3.15 1.162q.725.375 1.163 1.088T17 17.2V20z" />
  </svg>
);

PersonCancel.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(PersonCancel);
