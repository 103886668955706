import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const WebeX = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.78 7.376c.512 1.181.032 2.644-1.11 3.106c-2.157.888-3-1.295-3-1.295c-.236-.55-.727-1.496-1.335-1.496c-.204 0-.503 0-.94.844c-.229.443-.434 1.185-.616 1.84l-.09.32c-.373-1.587-.821-3.454-1.536-4.816q-.293-.57-.673-1.08a5.1 5.1 0 0 1 1.743-1.337a4.9 4.9 0 0 1 2.112-.463c1.045 0 2.765.338 4.227 2.227q.251.309.448.654q.417.663.726 1.383l.043.113zM.02 8.4C-.15 7.105.8 5.845 1.953 5.755c1.794-.157 2.36 1.385 2.455 1.89l.022.137c.07.44.29 1.838.48 2.744c.078.4.244 1.013.353 1.416l.006.022l.026.092q.166.601.362 1.193q.278.822.641 1.61c.47.955.93 1.45 1.367 1.45c.203 0 .512 0 .96-.878c.283-.59.512-1.208.684-1.845c.373 1.598.811 3.128 1.495 4.456q.308.61.715 1.16a5.1 5.1 0 0 1-1.742 1.338a4.9 4.9 0 0 1-2.112.461c-1.548 0-3.727-.698-5.339-4.005a22 22 0 0 1-1.078-2.824a27 27 0 0 1-.693-2.656a49 49 0 0 1-.215-1.114A32 32 0 0 1 .02 8.4m22.047-2.645l-.202-.022h-.052q.333.588.597 1.215l.053.113a3.3 3.3 0 0 1 .068 2.391a3.08 3.08 0 0 1-1.552 1.749a2.9 2.9 0 0 1-1.228.28a3.1 3.1 0 0 1-.854-.135c-.299 1.182-.768 2.634-1.195 3.511s-.93 1.451-1.378 1.451c-.192 0-.501 0-.95-.877a11 11 0 0 1-.683-1.845a39 39 0 0 1-.396-1.575a13 13 0 0 1-.136-.598l-.002-.01c-.406-1.778-.865-3.645-1.655-5.142A8.3 8.3 0 0 0 11.52 4.8a5.1 5.1 0 0 0-1.748-1.34A4.9 4.9 0 0 0 7.654 3C6.618 3 4.9 3.338 3.437 5.228c.466.223.867.562 1.164.984c.305.433.499.933.565 1.458c.076.563.256 1.654.47 2.688l.001.007c.021.11.042.221.073.342c.126-.34.25-.642.38-.955l.112-.271l.128-.293c.235-.55.726-1.496 1.324-1.496c.213 0 .513 0 .95.844c.296.606.532 1.239.706 1.89c.138.507.276 1.047.394 1.587c.04.148.07.296.101.444l.006.028c.427 1.879.875 3.69 1.644 5.187q.238.477.545.911q.225.323.48.62c1.27 1.45 2.733 1.8 3.843 1.8c1.548 0 3.738-.698 5.35-4.006c.822-1.7 1.515-4.208 1.772-5.48c.256-1.27.449-2.419.534-3.115a2.42 2.42 0 0 0-.433-1.743a2.3 2.3 0 0 0-.653-.607a2.1 2.1 0 0 0-.826-.296z"
    />
  </svg>
);

WebeX.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(WebeX);
