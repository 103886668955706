import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const Hangouts = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="m15 11l-1 2h-1.5l1-2H12V8h3m-4 3l-1 2H8.5l1-2H8V8h3m.5-6A8.5 8.5 0 0 0 3 10.5a8.5 8.5 0 0 0 8.5 8.5h.5v3.5c4.86-2.35 8-7.5 8-12A8.5 8.5 0 0 0 11.5 2"
    />
  </svg>
);

Hangouts.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(Hangouts);
