import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const Summarize = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h11l5 5v11q0 .825-.587 1.413T19 21zm3-4q.425 0 .713-.288T9 16q0-.425-.288-.712T8 15q-.425 0-.712.288T7 16q0 .425.288.713T8 17m0-4q.425 0 .713-.288T9 12q0-.425-.288-.712T8 11q-.425 0-.712.288T7 12q0 .425.288.713T8 13m0-4q.425 0 .713-.288T9 8q0-.425-.288-.712T8 7q-.425 0-.712.288T7 8q0 .425.288.713T8 9m7 0h4l-4-4z"
    />
  </svg>
);

Summarize.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(Summarize);
