import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const Grading = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.425 20.975L13.6 18.15l1.4-1.4l1.425 1.425L19.6 15l1.4 1.4zM3 21v-2h9v2zm0-4v-2h9v2zm0-4v-2h18v2zm0-4V7h18v2zm0-4V3h18v2z"
    />
  </svg>
);

Grading.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(Grading);
