import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const ArrowRightLeft = props => (
  <svg
    {...props}
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="arrows-alt-h"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={props.theme.palette.icons.darkInactiveIcon}
      d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z"
    />
  </svg>
);

ArrowRightLeft.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(ArrowRightLeft);
