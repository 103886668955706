import React from "react";
import { SvgIcon } from "@material-ui/core";

const Variable = props => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.88 6.52">
      <g id="Camada_2" data-name="Camada 2">
        <g id="Camada_1-2" data-name="Camada 1">
          <path d="M3.31,5.71c0-.19-.06-.38-.09-.59L3.1,4.5,3,3.87c0-.2-.09-.4-.13-.58l-.13-.51c0-.16-.08-.3-.12-.41a2.17,2.17,0,0,0-.1-.28.63.63,0,0,0-.1-.17.24.24,0,0,0-.12-.09.39.39,0,0,0-.15,0,.71.71,0,0,0-.2,0l-.26,0,0-.21L2,1.55l.32-.09.25-.06.18,0a.32.32,0,0,1,.14,0A.51.51,0,0,1,3,1.5a1,1,0,0,1,.12.19c0,.07.07.17.11.29s0,.18.09.3.07.27.1.42.08.33.12.52.08.37.11.57.08.4.11.6.06.41.08.61c.1-.15.2-.29.3-.45s.19-.3.28-.46.18-.31.25-.47a5,5,0,0,0,.21-.48A4,4,0,0,0,5,2.68a2.18,2.18,0,0,0,0-.43,1.21,1.21,0,0,0-.1-.5.91.91,0,0,0-.31-.4l0-.07h.2a.81.81,0,0,1,.59.19.63.63,0,0,1,.2.51,1.8,1.8,0,0,1-.1.57,5.9,5.9,0,0,1-.28.66c-.12.24-.25.47-.39.71s-.3.48-.45.7S4.15,5,4,5.23s-.27.37-.38.51Z" />
          <path d="M8.47,2.6a1,1,0,0,0-.07-.4.75.75,0,0,0-.2-.28.66.66,0,0,0-.28-.16.85.85,0,0,0-.31-.06,1.13,1.13,0,0,0-.35.05A.67.67,0,0,0,7,1.87a.36.36,0,0,0-.12.18.54.54,0,0,0,0,.22.66.66,0,0,0,0,.28.85.85,0,0,0,.15.28v0A.75.75,0,0,1,6.71,3a.58.58,0,0,1-.19,0,.6.6,0,0,1-.15-.09.39.39,0,0,1-.1-.14.58.58,0,0,1,0-.19.72.72,0,0,1,.08-.32,1,1,0,0,1,.22-.28,1.54,1.54,0,0,1,.32-.23l.38-.17a2.66,2.66,0,0,1,.38-.1,1.61,1.61,0,0,1,.37,0,1.58,1.58,0,0,1,.52.08,1,1,0,0,1,.36.24,1.15,1.15,0,0,1,.2.38,2,2,0,0,1,.06.51c0,.12,0,.27,0,.42s0,.32,0,.49,0,.34,0,.52,0,.36,0,.53a1.09,1.09,0,0,0,.16.64.48.48,0,0,0,.41.21,1,1,0,0,0,.51-.17l.08.13a1.39,1.39,0,0,1-.43.3,1.12,1.12,0,0,1-.48.1,1,1,0,0,1-.35-.06.72.72,0,0,1-.28-.2.77.77,0,0,1-.18-.32,1.16,1.16,0,0,1-.07-.44.86.86,0,0,1,0-.16.92.92,0,0,1,0-.16h0a3.68,3.68,0,0,1-.3.5,3.13,3.13,0,0,1-.36.44,2.07,2.07,0,0,1-.42.31,1,1,0,0,1-.47.11.87.87,0,0,1-.37-.07.81.81,0,0,1-.29-.19A1,1,0,0,1,6,5.2a1.11,1.11,0,0,1-.07-.37A1.2,1.2,0,0,1,6,4.36,1.18,1.18,0,0,1,6.3,4a1.52,1.52,0,0,1,.41-.29,3,3,0,0,1,.51-.21,4.56,4.56,0,0,1,.58-.12,6.26,6.26,0,0,1,.63,0l0-.35C8.47,2.86,8.47,2.74,8.47,2.6Zm-1.8,2a.78.78,0,0,0,0,.22A.49.49,0,0,0,6.78,5a.44.44,0,0,0,.14.13.4.4,0,0,0,.2,0,.74.74,0,0,0,.27-.06A1.75,1.75,0,0,0,7.67,5a2.12,2.12,0,0,0,.26-.26,2.7,2.7,0,0,0,.22-.33A2.19,2.19,0,0,0,8.32,4a1.77,1.77,0,0,0,.08-.4,3.11,3.11,0,0,0-.43,0,2.4,2.4,0,0,0-.4.06,3,3,0,0,0-.36.13A.92.92,0,0,0,6.93,4a.83.83,0,0,0-.19.26A1,1,0,0,0,6.67,4.61Z" />
          <path d="M10.76,5.68h0c0-.18,0-.39,0-.61s0-.44,0-.66l0-.67c0-.22,0-.43,0-.62s0-.37,0-.52,0-.27,0-.36,0-.14,0-.19a.4.4,0,0,0,0-.13.18.18,0,0,0-.09-.08l-.16,0h-.1l-.12,0-.14,0-.19,0,0-.21a7.34,7.34,0,0,1,.78-.25,2,2,0,0,1,.41-.07.32.32,0,0,1,.28.11.59.59,0,0,1,.08.33V2c0,.07,0,.16,0,.26s0,.23,0,.37l0,.46c0,.16,0,.33,0,.5s0,.35-.05.52h0A9.05,9.05,0,0,1,11.71,3a5,5,0,0,1,.39-.87,2.15,2.15,0,0,1,.49-.57.92.92,0,0,1,.58-.21.66.66,0,0,1,.21,0,1,1,0,0,1,.17.1.58.58,0,0,1,.11.15.41.41,0,0,1,0,.19.54.54,0,0,1-.06.25.63.63,0,0,1-.18.22h0a.9.9,0,0,0-.23-.15.67.67,0,0,0-.24,0,.53.53,0,0,0-.25,0,.79.79,0,0,0-.23.14,1.68,1.68,0,0,0-.31.4,4.9,4.9,0,0,0-.26.56,5.34,5.34,0,0,0-.21.64c-.07.22-.12.44-.17.65s-.08.41-.11.6,0,.34-.06.47Z" />
          <path d="M1.65.2a1.77,1.77,0,0,0-.33.19,1.05,1.05,0,0,0-.27.24,1.21,1.21,0,0,0-.2.26.94.94,0,0,0-.12.28.9.9,0,0,0,0,.21.6.6,0,0,0,.05.16l.09.13a.83.83,0,0,1,.08.12A.39.39,0,0,1,1,1.94a.44.44,0,0,1,0,.19.3.3,0,0,1-.07.15.37.37,0,0,1-.13.12.44.44,0,0,1-.16.06.41.41,0,0,1-.19,0,.55.55,0,0,1-.23-.11.56.56,0,0,1-.14-.2.75.75,0,0,1,0-.26,1.15,1.15,0,0,1,0-.31,1.73,1.73,0,0,1,.17-.44A2.14,2.14,0,0,1,.53.69,2.4,2.4,0,0,1,1,.3,2.65,2.65,0,0,1,1.55,0Z" />
          <path d="M13.08,6.33a1.74,1.74,0,0,0,.38-.14A2,2,0,0,0,13.78,6,1.62,1.62,0,0,0,14,5.68a1,1,0,0,0,.14-.3.52.52,0,0,0,0-.21.75.75,0,0,0,0-.16A.47.47,0,0,0,14,4.89L14,4.78a.5.5,0,0,1,0-.13.38.38,0,0,1,0-.18A.29.29,0,0,1,14,4.31a.53.53,0,0,1,.13-.13.38.38,0,0,1,.16-.06.41.41,0,0,1,.19,0,.46.46,0,0,1,.23.12.6.6,0,0,1,.14.21.7.7,0,0,1,.05.25,1.12,1.12,0,0,1,0,.27,1.58,1.58,0,0,1-.2.47,2.15,2.15,0,0,1-.35.46,2.32,2.32,0,0,1-.5.37,2.17,2.17,0,0,1-.63.24Z" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default Variable;
