import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const Twitter = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.205 2.25h3.308l-7.227 8.26l8.502 11.24H16.13l-5.214-6.817L4.95 21.75H1.64l7.73-8.835L1.215 2.25H8.04l4.713 6.231zm-1.161 17.52h1.833L7.045 4.126H5.078z"
    />
  </svg>
);

Twitter.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(Twitter);
