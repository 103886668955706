import React from "react";
import PropTypes from "prop-types";

function TallosIcon({ color, size, ...props }) {
  return (
    <svg
      id="Bold"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      {...props}
      height={size}
      fill={color}
      width={size}
    >
      <path
        d="M17.0089 4.73569C16.8284 2.60402 15.0465 1.17096 12.8845 1.15702C10.266 1.1402 7.64727 1.13716 5.02882 1.1583C2.66245 1.17752 0.87539 2.97455 0.860939 5.31846C0.850824 6.97115 0.848415 8.624 0.861741 10.2767C0.876513 12.0945 1.99435 13.5694 3.5677 14.1455C3.34644 13.4406 3.28302 12.6166 3.26102 11.9967C2.82413 11.573 2.59116 11.027 2.57976 10.3685C2.54989 8.63553 2.54138 6.90099 2.58185 5.16836C2.61203 3.87178 3.58183 2.88692 4.89169 2.86754C7.61756 2.82733 10.3449 2.83134 13.0711 2.86642C14.3137 2.88244 15.3115 3.82548 15.3239 5.02836C15.3623 8.7616 15.3592 12.4953 15.335 16.2286C15.3284 17.2402 14.3196 17.871 13.4742 17.3397C11.7386 16.2491 10.0449 15.0919 8.34362 13.9431C8.3364 13.952 8.32917 13.9608 8.32211 13.9693C8.21662 14.0972 8.12671 14.2065 8.03358 14.2994L7.96743 14.3665C7.76351 14.5745 7.47755 14.8655 7.06218 15.1141C8.75547 16.2328 10.4386 17.3671 12.1083 18.5203C13.1627 19.2485 14.2509 19.4707 15.4172 18.8695C16.6042 18.2577 17.1204 17.2178 17.1338 15.922C17.1514 14.1894 17.1768 6.71853 17.0089 4.73569Z"
        fill="#002D6E"
      />
      <path
        d="M5.58604 8.3163C5.58604 6.27194 5.69266 6.28379 9.11569 6.28379C12.5386 6.28379 12.6964 6.23654 12.6964 8.36355C12.6964 10.4906 11.426 10.3703 11.2398 10.3717C10.7325 10.3759 10.2234 10.4017 9.7186 10.3671C8.88656 10.3102 8.21686 10.5896 7.63787 11.1826C7.14221 11.6906 6.37858 12.7374 5.97043 12.7374C5.56228 12.7374 5.58604 10.7743 5.58604 8.3163ZM5.13599 14.8391C6.50992 14.8391 7.11363 14.1195 7.481 13.7527C7.84821 13.3857 8.5945 12.1213 9.99203 12.0503C10.3316 12.0255 11.2399 12.0832 11.4536 12.0787C13.0398 12.0443 14.3389 12.724 14.3389 8.6354C14.3389 4.54668 14.2026 4.60531 9.0179 4.60531C3.83319 4.60531 3.8478 4.76711 4.01783 11.3341C4.03148 11.8613 3.99889 14.8391 5.13599 14.8391Z"
        fill="#009ACC"
      />
      <path
        d="M6.83559 17.6697C6.83559 18.4596 6.19382 19.0999 5.40209 19.0999C4.61052 19.0999 3.96875 18.4596 3.96875 17.6697C3.96875 16.8798 4.61052 16.2395 5.40209 16.2395C6.19382 16.2395 6.83559 16.8798 6.83559 17.6697Z"
        fill="#009ACC"
      />
    </svg>
  );
}

TallosIcon.defaultProps = {
  color: "#fff",
  size: 24,
};

TallosIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default TallosIcon;
