import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const GppMaybeOutlined = props => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={"#ED6C02"} {...props}>
    <g>
      <path d="M0,0h24v24H0V0z" fill="none" />
    </g>
    <g>
      <g>
        <path d="M12,2L4,5v6.09c0,5.05,3.41,9.76,8,10.91c4.59-1.15,8-5.86,8-10.91V5L12,2z M18,11.09c0,4-2.55,7.7-6,8.83 c-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25l6,2.25V11.09z" />
        <rect height="2" width="2" x="11" y="14" />
        <rect height="5" width="2" x="11" y="7" />
      </g>
    </g>
  </svg>
);

GppMaybeOutlined.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(GppMaybeOutlined);
