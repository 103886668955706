import React from "react";
import { withTheme } from "@material-ui/core/styles";
import propTypes from "prop-types";

const MissedVideoCall = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill={props.theme.palette.icons.darkActiveIcon}
    {...props}
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <path d="M18,10.48V6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-4.48l4,3.98v-11L18,10.48z M16,9.69V18H4V6h12V9.69z" />
        <circle cx="10" cy="10" r="2" />
        <path d="M14,15.43c0-0.81-0.48-1.53-1.22-1.85C11.93,13.21,10.99,13,10,13c-0.99,0-1.93,0.21-2.78,0.58C6.48,13.9,6,14.62,6,15.43 V16h8V15.43z" />
      </g>
    </g>
  </svg>
);

MissedVideoCall.propTypes = {
  theme: propTypes.object,
};

export default withTheme()(MissedVideoCall);
